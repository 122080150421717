/* styles */

:root {
  --layout-headerHeight: 4.5rem;
  --layout-footerSepHeight: 4.5rem;
  --z-tickets: 300;
  --z-messages: 700;
  --z-modals: 1000;
  --z-header: 3000;
  --z-contact: 7000;
  --z-loading: 10000;
  --z-auth: 50000;
  --font-serif: "Bitter", "Roboto Slab", serif;
  --font-sans: "Rubik", "Roboto", "Lato", "Open Sans", sans-serif;
  --font-mono: "FiraCode-Retina", "Fira Code", "Roboto Mono", "Menlo", "Monaco", monospace;
  --font-head: "Bitter", "Roboto Slab", serif;
  --font-body: "Rubik", "Roboto", "Lato", "Open Sans", sans-serif;
  --font-logo: "Bungee", sans-serif;
  --rgb-white: 246, 248, 249;
  --rgb-black: 25, 26, 26;
  --rgb-gray: 126, 128, 129;
  --rgb-red: 231, 111, 81;
  --rgb-orange: 248, 168, 71;
  --rgb-yellow: 255, 205, 103;
  --rgb-green: 77, 153, 92;
  --rgb-blue: 58, 168, 195;
  --rgb-purple: 152, 87, 110;
  --rgb-prussia: 42, 122, 141;
  --rpx: 0.0625rem;
  --baseline: 16px;
  --bp-xxs: 18rem;
  --bp-xs: 27rem;
  --bp-s: 36rem;
  --bp-sm: 45rem;
  --bp-m: 54rem;
  --bp-ml: 63rem;
  --bp-l: 72rem;
  --bp-xl: 81rem;
  --bp-xxl: 90rem;
  --bp-min-xxs: 18em;
  --bp-min-xs: 27em;
  --bp-min-s: 36em;
  --bp-min-sm: 45em;
  --bp-min-m: 54em;
  --bp-min-ml: 63em;
  --bp-min-l: 72em;
  --bp-min-xl: 81em;
  --bp-min-xxl: 90em;
  --bp-max-xxs: 17.99875em;
  --bp-max-xs: 26.99875em;
  --bp-max-s: 35.99875em;
  --bp-max-sm: 44.99875em;
  --bp-max-m: 53.99875em;
  --bp-max-ml: 62.99875em;
  --bp-max-l: 71.99875em;
  --bp-max-xl: 80.99875em;
  --bp-max-xxl: 89.99875em;
  --white: #f6f8f9;
  --black: #191a1a;
  --gray: #7e8081;
  --red: #e76f51;
  --orange: #f8a847;
  --yellow: #ffcd67;
  --green: #4d995c;
  --blue: #3aa8c3;
  --purple: #98576e;
  --prussia: #2a7a8d;
  --gray-0: #000102;
  --gray-5: #0f1112;
  --gray-10: #1a1c1d;
  --gray-15: #242627;
  --gray-20: #2f3131;
  --gray-25: #3a3c3d;
  --gray-30: #454748;
  --gray-35: #515354;
  --gray-40: #5d5f60;
  --gray-45: #696b6c;
  --gray-50: #757778;
  --gray-55: #828485;
  --gray-60: #8f9192;
  --gray-65: #9c9e9f;
  --gray-70: #a9abac;
  --gray-75: #b7b9ba;
  --gray-80: #c5c7c8;
  --gray-85: #d2d5d6;
  --gray-90: #e1e3e4;
  --gray-95: #eff1f2;
  --gray-100: #fdfeff;
  --red-0: #3d1500;
  --red-5: #461600;
  --red-10: #511000;
  --red-15: #5e0900;
  --red-20: #6c0500;
  --red-25: #7a0800;
  --red-30: #88190a;
  --red-35: #972815;
  --red-40: #a63620;
  --red-45: #b5442b;
  --red-50: #c45136;
  --red-55: #d35e42;
  --red-60: #e36b4e;
  --red-65: #f2795a;
  --red-70: #ff8666;
  --red-75: #ff9073;
  --red-80: #ff9a7f;
  --red-85: #ffa58c;
  --red-90: #ffaf9a;
  --red-95: #ffbaa7;
  --red-100: #ffc5b5;
  --orange-0: #342000;
  --orange-5: #392600;
  --orange-10: #422b00;
  --orange-15: #4d3200;
  --orange-20: #583900;
  --orange-25: #654000;
  --orange-30: #724700;
  --orange-35: #804f00;
  --orange-40: #8e5800;
  --orange-45: #9c6200;
  --orange-50: #ab6a00;
  --orange-55: #ba7207;
  --orange-60: #c97f1b;
  --orange-65: #d88c2a;
  --orange-70: #e79938;
  --orange-75: #f6a645;
  --orange-80: #ffb052;
  --orange-85: #ffb660;
  --orange-90: #ffbb6d;
  --orange-95: #ffc17a;
  --orange-100: #ffc788;
  --yellow-0: #291d00;
  --yellow-5: #2f2400;
  --yellow-10: #352a00;
  --yellow-15: #3f3100;
  --yellow-20: #493900;
  --yellow-25: #554100;
  --yellow-30: #614a00;
  --yellow-35: #6e5300;
  --yellow-40: #7b5d00;
  --yellow-45: #896800;
  --yellow-50: #977000;
  --yellow-55: #a67c12;
  --yellow-60: #b48923;
  --yellow-65: #c29631;
  --yellow-70: #d1a33e;
  --yellow-75: #e0b04c;
  --yellow-80: #efbe59;
  --yellow-85: #fecc66;
  --yellow-90: #ffd173;
  --yellow-95: #ffd581;
  --yellow-100: #ffd98e;
  --green-0: #001602;
  --green-5: #001f02;
  --green-10: #002604;
  --green-15: #00300b;
  --green-20: #003b17;
  --green-25: #00471f;
  --green-30: #005324;
  --green-35: #086028;
  --green-40: #1c6c33;
  --green-45: #2b793f;
  --green-50: #3a864b;
  --green-55: #479357;
  --green-60: #54a063;
  --green-65: #62ae6f;
  --green-70: #6fbc7c;
  --green-75: #7dca89;
  --green-80: #8ad896;
  --green-85: #98e6a4;
  --green-90: #a6f4b1;
  --green-95: #b4ffbe;
  --green-100: #c2ffca;
  --blue-0: #002025;
  --blue-5: #002930;
  --blue-10: #00313b;
  --blue-15: #003b47;
  --blue-20: #004752;
  --blue-25: #00535e;
  --blue-30: #005e6b;
  --blue-35: #006977;
  --blue-40: #007584;
  --blue-45: #007f91;
  --blue-50: #00889e;
  --blue-55: #1291ac;
  --blue-60: #2c9eb9;
  --blue-65: #3facc7;
  --blue-70: #50b9d5;
  --blue-75: #5fc7e3;
  --blue-80: #6fd5f1;
  --blue-85: #7ee3ff;
  --blue-90: #8ce6ff;
  --blue-95: #9be9ff;
  --blue-100: #aaecff;
  --purple-0: #2a0012;
  --purple-5: #310018;
  --purple-10: #3c011e;
  --purple-15: #490e28;
  --purple-20: #551a33;
  --purple-25: #62263e;
  --purple-30: #6f3249;
  --purple-35: #7c3e55;
  --purple-40: #8a4a61;
  --purple-45: #97566d;
  --purple-50: #a5637a;
  --purple-55: #b36f86;
  --purple-60: #c17c93;
  --purple-65: #cf89a1;
  --purple-70: #dd97ae;
  --purple-75: #eca4bc;
  --purple-80: #fab2c9;
  --purple-85: #ffbfd4;
  --purple-90: #ffcddd;
  --purple-95: #ffdce7;
  --purple-100: #ffeaf1;
  --prussia-0: #001a1e;
  --prussia-5: #002228;
  --prussia-10: #002a33;
  --prussia-15: #00353f;
  --prussia-20: #00404a;
  --prussia-25: #004a56;
  --prussia-30: #005562;
  --prussia-35: #005e6e;
  --prussia-40: #0d687b;
  --prussia-45: #237588;
  --prussia-50: #338195;
  --prussia-55: #428ea2;
  --prussia-60: #509caf;
  --prussia-65: #5ea9bd;
  --prussia-70: #6cb7cb;
  --prussia-75: #7ac4d9;
  --prussia-80: #88d2e7;
  --prussia-85: #96e0f5;
  --prussia-90: #a4ebff;
  --prussia-95: #b2eeff;
  --prussia-100: #c1f1ff;
  --white-0: rgb(246 248 249 / 0%);
  --white-5: rgb(246 248 249 / 5%);
  --white-10: rgb(246 248 249 / 10%);
  --white-15: rgb(246 248 249 / 15%);
  --white-20: rgb(246 248 249 / 20%);
  --white-25: rgb(246 248 249 / 25%);
  --white-30: rgb(246 248 249 / 30%);
  --white-35: rgb(246 248 249 / 35%);
  --white-40: rgb(246 248 249 / 40%);
  --white-45: rgb(246 248 249 / 45%);
  --white-50: rgb(246 248 249 / 50%);
  --white-55: rgb(246 248 249 / 55%);
  --white-60: rgb(246 248 249 / 60%);
  --white-65: rgb(246 248 249 / 65%);
  --white-70: rgb(246 248 249 / 70%);
  --white-75: rgb(246 248 249 / 75%);
  --white-80: rgb(246 248 249 / 80%);
  --white-85: rgb(246 248 249 / 85%);
  --white-90: rgb(246 248 249 / 90%);
  --white-95: rgb(246 248 249 / 95%);
  --white-100: #f6f8f9;
  --black-0: rgb(25 26 26 / 0%);
  --black-5: rgb(25 26 26 / 5%);
  --black-10: rgb(25 26 26 / 10%);
  --black-15: rgb(25 26 26 / 15%);
  --black-20: rgb(25 26 26 / 20%);
  --black-25: rgb(25 26 26 / 25%);
  --black-30: rgb(25 26 26 / 30%);
  --black-35: rgb(25 26 26 / 35%);
  --black-40: rgb(25 26 26 / 40%);
  --black-45: rgb(25 26 26 / 45%);
  --black-50: rgb(25 26 26 / 50%);
  --black-55: rgb(25 26 26 / 55%);
  --black-60: rgb(25 26 26 / 60%);
  --black-65: rgb(25 26 26 / 65%);
  --black-70: rgb(25 26 26 / 70%);
  --black-75: rgb(25 26 26 / 75%);
  --black-80: rgb(25 26 26 / 80%);
  --black-85: rgb(25 26 26 / 85%);
  --black-90: rgb(25 26 26 / 90%);
  --black-95: rgb(25 26 26 / 95%);
  --black-100: #191a1a;
}
