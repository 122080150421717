/* styles */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* stylelint-disable-next-line a11y/no-outline-none */
:where(*:focus) {
  outline: none;
}

:where(html) {
  width: 100%;
  min-height: 100%;
  position: relative;
  font-size: var(--baseline);
}

:where(body) {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  overflow-x: hidden;
  margin: 0;
  font-family: var(--font-body);
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: calc(var(--rpx) / -2);
  font-weight: 300;
  background: var(--white);
  color: var(--black);
}

:where(a, button) {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
